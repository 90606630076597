/* HexInput.css */
.hexagon-wrapper {
  width: 100px;
  height: 115px;
  position: relative;
  cursor: pointer;
}

.hexagon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e6e6e6;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease-out;
  /* border: 2px solid #ccc; */
}

.hexagon-wrapper.active .hexagon {
  background-color: #fffacd;
}

.hex-input {
  background: transparent;
  border: none;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  width: 50px;
  outline: none;
}

.hex-text {
  font-size: 36px;
  font-weight: 600;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hexagon-wrapper.active .hex-input {
  caret-color: black;
  animation: blink 1s infinite;
}
