/* HexBoard.css */
.hex-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hex-content {
  height: 100%;
  max-height: 800px;
  width: 100%;
  justify-content: space-between;
}

.hex-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.hex-row {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.hex-row:nth-child(2),
.hex-row:nth-child(3),
.hex-row:nth-child(4),
.hex-row:nth-child(5) {
  margin-top: -22px;
}

.hex-cell {
  margin: 0;
}

.action-button {
  width: 100px;
  height: 40px;
  font-size: 18px !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
  border: 0px;
}

.clear-button {
  background-color: #ff6b6b !important;
}

.clear-button:hover {
  background-color: #ff4757 !important;
  cursor: pointer;
}

.submit-button {
  background-color: #4ecdc4 !important;
}

.submit-button:hover {
  background-color: #45b7aa !important;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #a5a5a5 !important;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
